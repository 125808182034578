$( document ).ready(function() {
  // Learn more content
  $('.support-package-cards .card-link').click(function(e){
    $learnmore = $(this);
    $(this).hide();
    $learnmore.siblings('.spinner-border').show();
    setTimeout(
      function() 
      {
        $learnmore.siblings('.spinner-border').hide();
        $learnmore.siblings('.details').show();
      }, 1000);
    e.preventDefault();
  });
});
